import React, { useState } from 'react'
import { useForm } from 'react-hook-form';

function DriverDetailsForm(props) {

  const [currentStep, setCurrentStep] = useState(props.step);
  const [driverState, setDriverState] = useState(JSON.parse(localStorage.getItem('driver')))
  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: driverState
  });

  const backStep = () =>{
    props.getStep(3);
  }
  const onSubmit = (data) => {
    localStorage.setItem('driver', JSON.stringify(data))
    props.getData(data);
    props.getStep(5);
    console.log("Current step ==="+currentStep)
    
  };

  return (
    <div>
       <div className="col-lg-12 col-md-12" style={{marginTop:"1%"}}>
                  <div className="card-body p-4 p-lg-5 text-black" style={{backgroundColor:"#fff", minHeight:'50vh'}}>
    
                  
               <form onSubmit={handleSubmit(onSubmit)}>
               <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Driver First Name *</label>
                <input type="text" className="form-control" name="driverFirstName" {...register("driverFirstName", { required: true })} />
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Driver Last Name *</label>
                <input type="text" className="form-control" name="driverLastName" {...register("driverLastName", { required: true })} />
              </div>

            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Mobile No *</label>
                <input type="text" className="form-control" name="driverMobileNo" {...register("driverMobileNo", { required: true })} />
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Email Id *</label>
                <input type="text" className="form-control" name="driverEmailId" {...register("driverEmailId", { required: true })} />
              </div>

            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">Gender *</label>
                <select className="form-select" name='driverGender' {...register("driverGender", { required: true })}>
                  <option value=''>Select Gender</option>
                 <option value='Male'>Male</option>
                 <option value='Female'>Female</option>
                 <option value='TransGender'>TransGender</option>
                </select>
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">DOB *</label>
                <input type="date" className="form-control" name="driverDob" {...register("driverDob", { required: true })} />
              </div>

            </div>

            <div className="row">
              <div className="col-lg-12 col-sm-12">
              <label className="form-label-dk">Driver Speking Language *</label>
                <select className="form-select" name='driverLanguage' {...register("driverLanguage", { required: true })}>
                  <option value=''>Select Language</option>
                 <option value='Hindi'>Hindi</option>
                 <option value='English'>English</option>
                 <option value='Bhojpuri'>Bhojpuri</option>
                </select>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col'>
             <>
                <button className="btn btn-warning" type='button' onClick={backStep}>Back</button>&nbsp;
                <button className="btn btn-warning" type="submit">Next Step</button>
              </>
             
              </div>
            </div>
               </form>
    
                  </div>
                </div>
    </div>
  )
}

export default DriverDetailsForm
