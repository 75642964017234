import React, { useEffect, useState } from 'react'

function SinglePageSlider(props) {

   // const [slideState, setSlideState] = useState(props.slide);

  useEffect(()=>{
    // if(props.slide != ''){
    //   setSlideState(props.slide);
    // }
     console.log('UPLOADS     ===='+props.slide)
  }, [props])
  return (
    <div>
      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
   
    {/* <div className="carousel-item active">
      <img src={require(`${process.env.REACT_APP_FEATURED_IMAGE}/${props.slide}`)} className="d-block w-100" alt="..." />
    </div> */}
    <div className="carousel-item active">
      <img src="https://png.pngtree.com/thumb_back/fw800/background/20200714/pngtree-modern-double-color-futuristic-neon-background-image_351866.jpg" className="d-block w-100" alt="..." />
    </div>
    <div className="carousel-item">
      <img src="https://png.pngtree.com/thumb_back/fw800/background/20200714/pngtree-modern-double-color-futuristic-neon-background-image_351866.jpg" className="d-block w-100" alt="..." />
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default SinglePageSlider
