import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Loader from '../../../../utils/Loader';
import ThankYouModal from '../../../../utils/ThankYouModal';

function SummaryForm(props) {

  
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userToken = localStorage.getItem('loginToken');
      let axiosConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${userToken}`
        }
      };

  const [currentStep, setCurrentStep] = useState(props.step);
  const [uploadState, setUploadState] = useState({});
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [feature, setFeature] = useState([{ feature: "" }]);
  const [loading, setLoading] = useState(false);
  const [openModal, setModal] = useState(false);
  const [failedResponse, setFailedResponse] = useState("");

  const [featuredImageState, setFeaturedImageState] = useState(localStorage.getItem('featuredImgPath'));
  const [galleryState, setGallaryState] = useState(localStorage.getItem('galleryImgPath'));

  const {
    brand,
        model,
        launchYear,
        vehicleColour,
        vehicleNo,
    organization,
    owner, 
    driver, 
    features,
    uploads} = props.getData;

  const backStep = () => {
    props.getStep(6);
  }
  const onSubmit = (data) => {
    const formData = new FormData();
    setLoading(true);
    setFailedResponse("");
    //setModal(false);
   // const submittedData = JSON.stringify(props.getData);
    formData.append('data', JSON.stringify(props.getData));
    formData.append('featuredImage', props.featuredImage);
    formData.append('gallery', props.galleryImage)


    console.log("FORM DATA REQUEST ===", formData)


    axios.post(`${baseUrl}/api/v1/transport/register`, formData, axiosConfig).then((res) =>{
      console.log("Response",res.data.data);
      setLoading(false);
      if(res.data.status === 'OK'){
      console.log("TRANSPORT REGISTERED SUCESSFULLY ==", res.data.data);
     
      localStorage.removeItem('owner');
      localStorage.removeItem('category');
      localStorage.removeItem('features');
      localStorage.removeItem('galleryImgPath');
      localStorage.removeItem('featuredImg');
      localStorage.removeItem('organization');
      localStorage.removeItem('subCategory');
      localStorage.removeItem('featuredImgPath');
      localStorage.removeItem('uploaded');
     
      setModal(true);
      }
      else if(res.data.status === 'BAD_REQUEST'){
        console.log("SOMETHING WENT WRONG ==", res.data.msg);
        setFailedResponse(res.data.msg);
        }
      
     }).catch(err =>{
       console.log("ERROR    ",err);
       setFailedResponse(err);
     });

    console.log("Request data is  ===", props.getData)

  };

  useEffect(() =>{
    console.log('TRANSPORT DATA IS  ==', props.getData)
  },[])
  return (
    <div>
      {loading ? <Loader /> : ''}
      <div className="col-lg-12 col-md-12" style={{ marginTop: "1%" }}>
        <div className="card-body p-4 p-lg-5 text-black" style={{ backgroundColor: "#fff" }}>


          <form noValidate onSubmit={handleSubmit(onSubmit)}  >
            <h3 style={{color:'#ff9800', fontSize:'13px'}}>Orgnization Details</h3>
            <section className='orgSection' style={{ border: '1px solid #ccc', padding:'1%' }}>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <label className="form-label-dk">Company Name *</label>
                  <input type="text" className="form-control" name="orgName" value={organization.orgName} readOnly/>


                </div>
                <div className="col-lg-6 col-sm-12">
                  <label className="form-label-dk">GST No</label>
                  <input type="text" className="form-control" name="gstNo" value={organization.gstNo} readOnly/>
                </div>

              </div>

              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <label className="form-label-dk">TIN No</label>
                  <input type="text" className="form-control" name="tinNo" value={organization.tinNo} readOnly />
                </div>
                <div className="col-lg-6 col-sm-12">
                  <label className="form-label-dk">Registration No</label>
                  <input type="text" className="form-control" name="regNo"  value={organization.regNo} readOnly/>
                </div>

              </div>
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <label className="form-label-dk">State *</label>
                  <select className="form-select" name='state' readOnly>

                    <option value={organization.state}>{organization.state}</option>


                  </select>

                </div>
                <div className="col-lg-6 col-sm-12">
                  <label className="form-label-dk">District *</label>
                  <select className="form-select" name='district'  readOnly >
                    <option value={organization.district}>{organization.district}</option>

                  </select>

                </div>

              </div>

              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <label className="form-label-dk">Area *</label>
                  <select className="form-select" name='area' readOnly >

                    <option value={organization.area}>{organization.area}</option>

                  </select>

                </div>
                <div className="col-lg-6 col-sm-12">
                  <label className="form-label-dk">Pin Code *</label>

                  <input type="text" className="form-control" name="pincode" value={organization.pincode} readOnly/>


                </div>


              </div>

              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <label className="form-label-dk">City *</label>
                  <input type="text" className="form-control" name="city" value={organization.city} readOnly />

                </div>
                <div className="col-lg-6 col-sm-12">
                  <label className="form-label-dk">Contact No</label>
                  <input type="text" className="form-control" name="contactNo" value={organization.contactNo} readOnly />

                </div>

              </div>
              <div className='row'>
                <div className="col">
                  <label className="form-label-dk">Office Address *</label>
                  <textarea className="form-control" name="address" value={organization.address} readOnly />

                </div>
              </div>
            </section>

            <h3 style={{color:'#ff9800', fontSize:'13px', paddingTop:'1%'}}>Vehicle Details</h3>
            <section className='orgSection' style={{ border: '1px solid #ccc', padding:'1%' }}>
            <div className="row">
            <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">Brand *</label>
              <input type="text" className="form-control" name="brand" value={brand} readOnly/>
    
            </div>
            <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">Model *</label>
              <input type="text" className="form-control" name="model" value={model} readOnly/>
         
            </div>

          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              <label className="form-label-dk">Colour *</label>
              <input type="text" className="form-control" name="vehicleColour" value={vehicleColour} readOnly/>
             
            </div>
            <div className="col-lg-4 col-sm-12">
              <label className="form-label-dk">Model Year *</label>
              <input type="text" className="form-control" name="launchYear" value={launchYear}  readOnly/>
              
            </div>
            <div className="col-lg-4 col-sm-12">
              <label className="form-label-dk">Vehicle Number *</label>
              <input type="text" className="form-control" name="vehicleNo" value={vehicleNo}  style={{textTransform: 'uppercase'}} readOnly/>
              
            </div>

          </div>
         
              </section>

            <h3 style={{color:'#ff9800', fontSize:'13px', paddingTop:'1%'}}>Owner Details</h3>
            <section className='orgSection' style={{ border: '1px solid #ccc', padding:'1%' }}>
            <div className="row">
            <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">Owner First Name *</label>
              <input type="text" className="form-control" name="ownerFirstName" value={owner.ownerFirstName} readOnly/>
    
            </div>
            <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">Owner Last Name *</label>
              <input type="text" className="form-control" name="ownerLastName" value={owner.ownerLastName} readOnly/>
         
            </div>

          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">Mobile No *</label>
              <input type="text" className="form-control" name="ownerMobileNo" value={owner.ownerMobileNo} readOnly/>
             
            </div>
            <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">Email Id *</label>
              <input type="text" className="form-control" name="ownerEmailId" value={owner.ownerEmailId}  readOnly/>
              
            </div>

          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">Gender *</label>
              <select className="form-select" name='ownerGender' value={owner.ownerGender} readOnly>
                <option value={owner.ownerGender}>{owner.ownerGender}</option>
                
              </select>
              
            </div>
            <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">DOB *</label>
              <input type="date" className="form-control" name="ownerDob" value={owner.ownerDob} readOnly />
             
            </div>

          </div>
              </section>

              <h3 style={{color:'#ff9800', fontSize:'13px', paddingTop:'1%'}}>Driver Details</h3>
            <section className='orgSection' style={{ border: '1px solid #ccc', padding:'1%' }}>
            <div className="row">
            <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">Owner First Name *</label>
              <input type="text" className="form-control" name="driverFirstName" value={driver.driverFirstName} readOnly/>
    
            </div>
            <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">Owner Last Name *</label>
              <input type="text" className="form-control" name="driverLastName" value={driver.driverLastName} readOnly/>
         
            </div>

          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">Mobile No *</label>
              <input type="text" className="form-control" name="driverMobileNo" value={driver.driverMobileNo} readOnly/>
             
            </div>
            <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">Email Id *</label>
              <input type="text" className="form-control" name="driverEmailId" value={driver.driverEmailId}  readOnly/>
              
            </div>

          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">Gender *</label>
              <select className="form-select" name='driverGender' value={driver.driverGender} readOnly>
                <option value={driver.driverGender}>{driver.driverGender}</option>
                
              </select>
              
            </div>
            <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">DOB *</label>
              <input type="date" className="form-control" name="driverDob" value={driver.driverDob} readOnly />
             
            </div>

          </div>

          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <label className="form-label-dk">Speking Language *</label>
              <select className="form-select" name='driverLanguage' value={driver.driverLanguage} readOnly>
                <option value={driver.driverLanguage}>{driver.driverLanguage}</option>
                
              </select>
              
            </div>
           

          </div>
              </section>

              <h3 style={{color:'#ff9800', fontSize:'13px', paddingTop:'1%'}}>Features</h3>
            <section className='orgSection' style={{ border: '1px solid #ccc', padding:'1%' }}>

            <div className="row">
                {/* {features.map((item, index)=>{
                  return(
                <div className="col-lg-12 col-sm-12">
                <label className="form-label-dk">Feature {index+1}</label>
                <div className='d-flex'>
                <input type="text" className="form-control width-90" name="feature" value={item.feature} readOnly />
                
               
                </div>
               
              </div>
                  )
                })} */}
              
             
              
            </div>
            </section>

            <h3 style={{color:'#ff9800', fontSize:'13px', paddingTop:'1%'}}>Uploads</h3>
            <section className='orgSection' style={{ border: '1px solid #ccc', padding:'1%' }}>
            <label className="form-label-dk"> Feature Image</label>
         <div className="row">
            <div className="col-lg-12 col-sm-12">
             
              {/* <input type="file" className="form-control" name="featuredImage" /> */}
              {featuredImageState && <img src={featuredImageState} style={{width:'150px', height: '200px'}} />}
            </div>
            

          </div>
</section>
          <section className='orgSection' style={{ border: '1px solid #ccc', padding:'1%', marginTop:'0%' }}>
          <label className="form-label-dk"> Gallery Image</label>
          <div className="row">

            <div className="col-lg-12 col-sm-12">
              
              {/* <input type="file" className="form-control" name="gallery"  /> */}
              {galleryState && <img src={galleryState} style={{width:'150px', height: '200px'}} />}
            </div>
          </div>

          
            </section>


            <div className='row mt-5'>
              <div className='col'>
              {failedResponse.length > 0 ? 
                  <div class="alert alert-danger" role="alert">
                      {failedResponse}
                   </div>:""
                   }
                <>
                  <button className="btn btn-warning" type='button' onClick={backStep}>Back</button>&nbsp;
                  <button className="btn btn-warning" type="submit">Submit</button>
                </>
                
              </div>
            </div>

          </form>

        </div>
      </div>
      {openModal && <ThankYouModal closeModal={setModal} /> }
    </div>
  )
}

export default SummaryForm
