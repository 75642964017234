import { put, takeEvery } from "redux-saga/effects";
import { HOTEL_STEP_ONE, SET_HOTEL_STEP_ONE } from "../../../constant";

function* getHotelFirstStepData(data) {
    console.log("action is called", data)
    yield put({type: SET_HOTEL_STEP_ONE, data})
}

function* hoteStepOneSaga() {
    yield takeEvery(HOTEL_STEP_ONE, getHotelFirstStepData)
}

export default hoteStepOneSaga;