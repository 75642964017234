import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { event } from 'jquery';
import { useForm } from 'react-hook-form';

function EditCompanyDetailsForm({ getData, getStep, currentData }) {


  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userToken = localStorage.getItem('loginToken');
  let axiosConfig = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${userToken}`
    }
  };

  

  const initialState = {
    id:"",
    address: "",
    area: "",
    city: "",
    contactNo: "",
    district:"" ,
    gstNo:"",
    orgName:"",
    pinCode:"",
    regNo:"",
    state:"",
    tinNo:""
  }
 // const [companyState, setCompanyState] = useState();
  const [orgState, setOrgState] = useState(currentData);
  

  const { register, handleSubmit, watch, setValue,reset, formState: { errors, isDirty, isSubmitting, touchedFields, submitCount, isValid} } = useForm({
    defaultValues: orgState,

  }
  );
  // const { register, handleSubmit, watch, setValue,reset, formState: { errors } } = useForm();

  const [currentStep, setCurrentStep] = useState(1);
  const [stateName, setStateName] = useState([]);
  const [districtName, setDistrictName] = useState([]);
  const [areaName, setAreaName] = useState([]);
  const [pinCode, setPinCode] = useState("");

  const [slectedState, setSelectedState] = useState("");
  const [slectedDistrict, setSelecteDistrict] = useState("");
  const [slectedArea, setSelectedArea] = useState("");
  const [failedResponse, setFailedResponse] = useState("");







  const getStateList = () => {
    axios.get(baseUrl + "/api/v1/common/get/detail/by/state/master", axiosConfig).then((res) => {

      if (res.data.status === 'OK') {
        console.log("Response for fetchdata", res.data);
        console.log("Response for fetchdata", res.data.status);
        setStateName(res.data.data);
      } else {
        setFailedResponse(res.data.msg)
      }

      // console.log(baseUrl,"BASE URL")
    }).catch(err => {
      console.log('Exception ===' + err);
    });
  }

  const stateChanged = (e) => {

    setSelectedState(e.target.value);
    console.log('Selected State is ', e.target.value);

    setDistrictName([]);
    setAreaName([]);

    if (e.target.value !== "") {
      axios.get(`${baseUrl}/api/v1/common/get/detail/by/district/master?stateName=${e.target.value}`, axiosConfig).then((res) => {

        if (res.data.status === 'OK') {
          console.log("Response for fetchdata", res.data);
          console.log("Response for fetchdata", res.data.status);
          setDistrictName(res.data.data);
        } else {
          setFailedResponse(res.data.msg)
        }

        // console.log(baseUrl,"BASE URL")
      }).catch(err => {
        console.log('Exception ===' + err);
      });
    }
    else {
      setDistrictName(['']);
      setAreaName(['']);
    }

  }

  const districtChanged = (e) => {
    console.log("District is is ==" + e.target.value);
    console.log("State is is is ==" + stateName);
    setSelecteDistrict(e.target.value)
    setAreaName(['']);
    if (e.target.value !== "" && stateName !== "") {
      axios.get(`${baseUrl}/api/v1/common/get/detail/by/area/master?stateName=${slectedState}&districtName=${e.target.value}`, axiosConfig).then((res) => {

        if (res.data.status === 'OK') {
          console.log("Response for fetchdata", res.data);
          console.log("Response for fetchdata", res.data.status);
          setAreaName(res.data.data);
        } else {
          setFailedResponse(res.data.msg)
        }

        // console.log(baseUrl,"BASE URL")
      }).catch(err => {
        console.log('Exception ===' + err);
      });
    }
    else {
      setAreaName([""]);
    }
  }


  const areaChanged = (e) => {
    console.log("District is is ==" + e.target.value);
    console.log("State is is is ==" + stateName);
    setSelectedArea(e.target.value)
    // setAreaName([]);
    if (e.target.value !== "" && stateName !== "" && slectedDistrict != "") {
      axios.get(`${baseUrl}/api/v1/common/get/detail/by/pincode/master?stateName=${slectedState}&districtName=${slectedDistrict}&area=${e.target.value}`, axiosConfig).then((res) => {

        if (res.data.status === 'OK') {
          console.log("Response for fetchdata", res.data);
          console.log("Response for fetchdata", res.data.status);
          setPinCode(res.data.data);
        } else {
          setFailedResponse(res.data.msg)
        }

        // console.log(baseUrl,"BASE URL")
      }).catch(err => {
        console.log('Exception ===' + err);
      });
    }
    else {
      setAreaName([""]);
    }
  }

  const onSubmit = (data) => {

   console.log('SUBMITTED DATA IS', data);
   console.log('CURRENT DATA IS', currentData);
    localStorage.setItem('organization', JSON.stringify(data))

    setOrgState({
      id: data?.id,
      address: data?.address,
      area: data?.area,
      city:data?.city,
      contactNo: data?.contactNo,
      district:data?.district ,
      gstNo:data?.gstNo,
      orgName:data?.orgName,
      pinCode:data?.pinCode,
      regNo:data?.regNo,
      state: data?.state,
      tinNo: data?.tinNo

    });
    setTimeout(() =>{
      getData(orgState);
      getStep(2);
    }, 1500)
    
    console.log("Current step ===" + currentStep)

  };


  useEffect(()=>{
 
    setOrgState(currentData);
    console.log('CURRENT DATA VALUE ===', currentData)
    console.log('ORG STATE ===', orgState)
   
  },[currentData])

  useEffect(()=>{
    setOrgState(currentData);
    getStep(1)
    getStateList();
    
  },[])

  
  return (
    <div>
      <div className="col-lg-12 col-md-12" style={{ marginTop: "1%" }}>
        <div className="card-body p-4 p-lg-5 text-black" style={{ backgroundColor: "#fff", minHeight: '50vh' }}>


          <form onSubmit={handleSubmit(onSubmit)}>

            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label className="form-label-dk">Organization / Company Name *  </label>
                <input type="text" className={`form-control ${ errors?.orgName ? 'is-invalid' : ''}`} name="orgName" {...register("orgName", { required: false})} defaultValue={currentData?.orgName} />
                {/* {errors.orgName && <span className="form-error-dk">This field is required</span>} */}
                {/* {errors.orgName?.type === 'required' && <span className="form-error-dk"> This field is required</span>}
                {errors.orgName?.type === 'pattern' && <span className="form-error-dk"> Invalid email</span>}
                {errors.orgName?.type === 'minLength' && <span className="form-error-dk"> Length Should be 5 chars</span>} */}
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">GST No (Optional)</label>
                <input type="text" className={`form-control ${errors?.gstNo ? 'is-invalid' : ''}`} name="gstNo" {...register("gstNo", { required: false })} defaultValue={currentData?.gstNo}  />
                {/* {errors.gstNo && <span className="form-error-dk">This field is required</span>} */}
              </div>

            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">TIN No (Optional)</label>
                <input type="text" className={`form-control ${errors.tinNo ? 'is-invalid' : ''}`} name="tinNo" {...register("tinNo", { required: false })} defaultValue={currentData?.tinNo} />
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Registration No (Optional)</label>
                <input type="text" className={`form-control ${errors.regNo ? 'is-invalid' : ''}`} name="regNo" {...register("regNo", { required: false })} defaultValue={currentData?.regNo}  />
              </div>

            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">State *</label>
                <select className={`form-select ${errors?.state ? 'is-invalid' : ''}`} name='state' {...register("state", { required: false })} onChange={stateChanged} defaultValue={currentData?.state}  >
                  <option value="">Select State</option>
                  {
                    stateName.length > 0 ? stateName.map((item, index) => {
                      return (<option key={index} value={item}>{item}</option>)

                    }) : ""
                  }

                </select>
                {/* {errors.state && <span className="form-error-dk">This field is required</span>} */}
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">District *</label>
                <select className={`form-select ${errors?.district ? 'is-invalid' : ''}`} name='district'  {...register("district", { required: false })} onChange={districtChanged} >
                  <option value="">Select District</option>
                  {
                    districtName.length > 0 ? districtName.map((item, index) => {
                      return (<option key={index} value={item}>{item}</option>)

                    }) : ""
                  }
                </select>
                {/* {errors.district && <span className="form-error-dk">This field is required</span>} */}
              </div>

            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Area *</label>
                <select className={`form-select ${errors?.area ? 'is-invalid' : ''}`} name='area' {...register("area", { required: false })} onChange={areaChanged}>
                  <option value="">Select Area</option>
                  {
                    areaName.length > 0 ? areaName.map((item, index) => {
                      return (<option key={index} value={item}>{item}</option>)

                    }) : ""
                  }
                </select>
                {/* {errors.area && <span className="form-error-dk">This field is required</span>} */}
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Pin Code *</label>

                {
                  pinCode.pincode != '' ?
                    <input type="text" className={`form-control ${errors.pinCode ? 'is-invalid' : ''}`}  name="pinCode" {...register("pinCode", { required: false })} value=''  />

                    : <input type="text" className="form-control" name="pinCode" value='' />
                }


              </div>


            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">City *</label>
                <input type="text" className={`form-control ${errors?.city ? 'is-invalid' : ''}`} name="city" {...register("city", { required: false })} defaultValue={currentData?.city} />
                {/* {errors.city && <span className="form-error-dk">This field is required</span>} */}
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Contact No</label>
                <input type="text" className={`form-control ${errors?.contactNo ? 'is-invalid' : ''}`} name="contactNo" {...register("contactNo", { required: false })} defaultValue={currentData?.contactNo} />
                {/* {errors.contactNo && <span className="form-error-dk">This field is required</span>} */}
              </div>

            </div>
            <div className='row'>
              <div className="col">
                <label className="form-label-dk">Office Address *</label>
                <textarea className={`form-control ${errors.address ? 'is-invalid' : ''}`} name="address" {...register("address", { required: false })} defaultValue={currentData?.address} />
                {/* {errors.address && <span className="form-error-dk">This field is required</span>} */}
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col'>

                <>
                  <button className="btn btn-warning" type="submit">Next Step</button>
                  {/* <button className="next_button" type="submit">Submit</button> */}

                  {/* <pre>{orgState.orgName}</pre> */}
                  <pre>{JSON.stringify(orgState)}</pre>

                </>


              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  )
}

export default EditCompanyDetailsForm
