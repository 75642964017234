import { HOTEL_STEP_ONE } from "../../../constant"

export const hotelOrgDetails = (data) => {
  
    return {
        type: HOTEL_STEP_ONE,
        payload: data
    }
}

