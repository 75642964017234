import React, { useEffect, useState } from 'react'
import HotelDetailsForm from './EntertainmentOrganizationDetailsForm'
import HotelProgressBar from './EntertainmentProgressBar'
import HotelOwnerDetailsForm from './EntertainmentOwnerDetailsForm';
import HotelFeatureForm from './EntertainmentFeatureForm';
import HotelUploadsForm from './EntertainmentUploadsForm';
import HotelSummaryForm from './EntertainmentSummaryForm';
import EntertainmentOrganizationDetailsForm from './EntertainmentOrganizationDetailsForm';
import EntertainmentOwnerDetailsForm from './EntertainmentOwnerDetailsForm';
import EntertainmentUploadsForm from './EntertainmentUploadsForm';
import EntertainmentSummaryForm from './EntertainmentSummaryForm';
import EntertainmentFeatureForm from './EntertainmentFeatureForm';

function EntertainmentCommon() {
  

    const [currentStep, setCurrentStep] = useState(1);
    const [orgState, setOrgState] = useState({});
    const [ownerState, setOwnerState] = useState({});
    const [featureState, setFeatureState] = useState([{}]);
    const [uploadState, setUploadState] = useState({});

    const [entertainmentState, setEntertainmentState] = useState({
      organization: '',
      owner:'',
      features:[{}],
      uploads:''
    })

    const [featuredImageState, setFeaturedImageState] = useState(null);
    const [galleryImageState, setGalleryImageState] = useState(null);

  

  function getOrgData(data)
  {
    console.log("PASSED DATA FROM CHILD IS =="+data);
    setOrgState(data);
  }

  function getOwnerData(data)
  {
    console.log("PASSED DATA FROM CHILD IS =="+data);
    setOwnerState(data);
  }

  function getFeatureData(data)
  {
    console.log("PASSED DATA FROM CHILD IS =="+data);
    setFeatureState(data);
  }

  function getUploadedData(data)
  {
    console.log("PASSED DATA FROM CHILD IS =="+data);

    setUploadState({
      featuredImage: data.featuredImage.name,
      gallery:[data.gallery.name]
    });

    setFeaturedImageState(data.featuredImage);
    setGalleryImageState(data.gallery);
  
  }


  function getSummaryData(data)
  {
    console.log("PASSED DATA FROM CHILD IS =="+data);
    setEntertainmentState(
      {
        organization: orgState,
        owner:ownerState,
        // features:featureState,
        uploads:uploadState
      }
    );
  }

  function getStep(step)
  {
    console.log("PASSED Step FROM CHILD IS =="+step);
    setCurrentStep(step);
  }

    // if(step != null){
    //   setCurrentStep(step);
    //   console.log("PASSED OBJECT IS =="+orgObject)
    // }


    useEffect(() =>{
      setEntertainmentState(
        {
          organization: orgState,
          owner:ownerState,
          // features:featureState,
          uploads:uploadState
        }
      );
    }, [orgState, ownerState, featureState,uploadState])
  return (
    <div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div style={{marginLeft:'15%'}}>
            <HotelProgressBar stepNo={currentStep} />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-8 col-md-8 mx-auto' style={{ backgroundColor: '#fff', marginTop: '1%' }}>
            {/* <form enctype="multipart/form-data"> */}
              {(() => {
                switch (currentStep) {
                  case 1:
                    return <EntertainmentOrganizationDetailsForm getData={getOrgData} getStep={getStep} />
                 
                  case 2:
                    return <EntertainmentOwnerDetailsForm getData={getOwnerData} getStep={getStep} />
                
                  case 3:
                    return <EntertainmentFeatureForm getData={getFeatureData} getStep={getStep}/>
                  case 4:
                    return <EntertainmentUploadsForm getData={getUploadedData} getStep={getStep} />
                  case 5:
                    // return <EntertainmentSummaryForm getData={hotelState} getStep={getStep} />
                    return <EntertainmentSummaryForm getData={entertainmentState} getStep={getStep} featuredImage={featuredImageState} galleryImage={galleryImageState} />

                }
              })()}
             
          </div>
         
        </div>
      </div>
    </div>
  )
}

export default EntertainmentCommon
