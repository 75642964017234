import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { event } from 'jquery';
import { useForm } from 'react-hook-form';

function CompanyDetailsForm({ getData, getStep }) {


  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userToken = localStorage.getItem('loginToken');
  let axiosConfig = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${userToken}`
    }
  };

  // const initialState = {
  //   orgName: "asdsa",
  //   gstNo: "",
  //   tinNo: "",
  //   regNo: "",
  //   state: "",
  //   district: "",
  //   city: "",
  //   address: "",
  //   pinCode: "",
  //   contactNo: ""
  // }
  const [companyState, setCompanyState] = useState();
  const [orgStorage, setOrgStorage] = useState(JSON.parse(localStorage.getItem('organization'))?JSON.parse(localStorage.getItem('organization')):'');
  

  const { register, handleSubmit, watch, setValue,reset, formState: { errors } } = useForm({
    defaultValues: orgStorage,

  }

  );
  const [currentStep, setCurrentStep] = useState(getStep);
  const [stateName, setStateName] = useState([]);
  const [districtName, setDistrictName] = useState([]);
  const [areaName, setAreaName] = useState([]);
  const [pinCode, setPinCode] = useState("");

  const [slectedState, setSelectedState] = useState("");
  const [slectedDistrict, setSelecteDistrict] = useState("");
  const [slectedArea, setSelectedArea] = useState("");
  const [failedResponse, setFailedResponse] = useState("");







  const getStateList = () => {
    axios.get(baseUrl + "/api/v1/common/get/detail/by/state/master", axiosConfig).then((res) => {

      if (res.data.status === 'OK') {
        console.log("Response for fetchdata", res.data);
        console.log("Response for fetchdata", res.data.status);
        setStateName(res.data.data);
      } else {
        setFailedResponse(res.data.msg)
      }

      // console.log(baseUrl,"BASE URL")
    }).catch(err => {
      console.log('Exception ===' + err);
    });
  }

  const stateChanged = (e) => {

    setSelectedState(e.target.value);
    console.log('Selected State is ', e.target.value);

    setDistrictName([]);
    setAreaName([]);

    if (e.target.value !== "") {
      axios.get(`${baseUrl}/api/v1/common/get/detail/by/district/master?stateName=${e.target.value}`, axiosConfig).then((res) => {

        if (res.data.status === 'OK') {
          console.log("Response for fetchdata", res.data);
          console.log("Response for fetchdata", res.data.status);
          setDistrictName(res.data.data);
        } else {
          setFailedResponse(res.data.msg)
        }

        // console.log(baseUrl,"BASE URL")
      }).catch(err => {
        console.log('Exception ===' + err);
      });
    }
    else {
      setDistrictName(['']);
      setAreaName(['']);
    }

  }

  const districtChanged = (e) => {
    console.log("District is is ==" + e.target.value);
    console.log("State is is is ==" + stateName);
    setSelecteDistrict(e.target.value)
    setAreaName(['']);
    if (e.target.value !== "" && stateName !== "") {
      axios.get(`${baseUrl}/api/v1/common/get/detail/by/area/master?stateName=${slectedState}&districtName=${e.target.value}`, axiosConfig).then((res) => {

        if (res.data.status === 'OK') {
          console.log("Response for fetchdata", res.data);
          console.log("Response for fetchdata", res.data.status);
          setAreaName(res.data.data);
        } else {
          setFailedResponse(res.data.msg)
        }

        // console.log(baseUrl,"BASE URL")
      }).catch(err => {
        console.log('Exception ===' + err);
      });
    }
    else {
      setAreaName([""]);
    }
  }


  const areaChanged = (e) => {
    console.log("District is is ==" + e.target.value);
    console.log("State is is is ==" + stateName);
    setSelectedArea(e.target.value)
    // setAreaName([]);
    if (e.target.value !== "" && stateName !== "" && slectedDistrict != "") {
      axios.get(`${baseUrl}/api/v1/common/get/detail/by/pincode/master?stateName=${slectedState}&districtName=${slectedDistrict}&area=${e.target.value}`, axiosConfig).then((res) => {

        if (res.data.status === 'OK') {
          console.log("Response for fetchdata", res.data);
          console.log("Response for fetchdata", res.data.status);
          setPinCode(res.data.data);
        } else {
          setFailedResponse(res.data.msg)
        }

        // console.log(baseUrl,"BASE URL")
      }).catch(err => {
        console.log('Exception ===' + err);
      });
    }
    else {
      setAreaName([""]);
    }
  }

  const onSubmit = (data) => {
    localStorage.setItem('organization', JSON.stringify(data))

    setCompanyState(data);
    getData(data);
    getStep(2);
    console.log("Current step ===" + currentStep)

  };



  useEffect(() => {

    getStep(1)
    getStateList();
    const items = JSON.parse(localStorage.getItem('organization'));

    console.log('LOCAL STORAGE  ===', items)
  
    // if (items != null) {
    //   setOrgStorage(items) 
    //   console.log('ORGANIZATION STATE  ===', orgStorage) 
    // }
 
    // if(orgStorage != null){
    //   setSelectedState(orgStorage.state);
    //   setSelecteDistrict(orgStorage.district);
    //   setSelectedArea(orgStorage.area);
    // }

  }, [])

  
  return (
    <div>
      <div className="col-lg-12 col-md-12" style={{ marginTop: "1%" }}>
        <div className="card-body p-4 p-lg-5 text-black" style={{ backgroundColor: "#fff", minHeight: '50vh' }}>


          <form onSubmit={handleSubmit(onSubmit)}>

            <div className="row">
              <div className="form-group col-lg-6 col-sm-12">
                <label className="form-label-dk">Organization / Company Name * </label>
                <input type="text" className={`form-control ${errors.orgName ? 'is-invalid' : ''}`} name="orgName" {...register("orgName", { required: true })} />
                {/* {errors.orgName && <span className="form-error-dk">This field is required</span>} */}

              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">GST No (Optional)</label>
                <input type="text" className={`form-control ${errors.gstNo ? 'is-invalid' : ''}`} name="gstNo" {...register("gstNo", { required: false })} />
                {/* {errors.gstNo && <span className="form-error-dk">This field is required</span>} */}
              </div>

            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">TIN No (Optional)</label>
                <input type="text" className={`form-control ${errors.tinNo ? 'is-invalid' : ''}`} name="tinNo" {...register("tinNo", { required: false })} />
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Registration No (Optional)</label>
                <input type="text" className={`form-control ${errors.regNo ? 'is-invalid' : ''}`} name="regNo" {...register("regNo", { required: false })} />
              </div>

            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">State *</label>
                <select className={`form-select ${errors.state ? 'is-invalid' : ''}`} name='state' {...register("state", { required: true })} onChange={stateChanged} >
                  <option value="">Select State</option>
                  {
                    stateName.length > 0 ? stateName.map((item, index) => {
                      return (<option key={index} value={item}>{item}</option>)

                    }) : ""
                  }

                </select>
                {/* {errors.state && <span className="form-error-dk">This field is required</span>} */}
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">District *</label>
                <select className={`form-select ${errors.district ? 'is-invalid' : ''}`} name='district'  {...register("district", { required: true })} onChange={districtChanged} >
                  <option value="">Select District</option>
                  {
                    districtName.length > 0 ? districtName.map((item, index) => {
                      return (<option key={index} value={item}>{item}</option>)

                    }) : ""
                  }
                </select>
                {/* {errors.district && <span className="form-error-dk">This field is required</span>} */}
              </div>

            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Area *</label>
                <select className={`form-select ${errors.area ? 'is-invalid' : ''}`} name='area' {...register("area", { required: true })} onChange={areaChanged}>
                  <option value="">Select Area</option>
                  {
                    areaName.length > 0 ? areaName.map((item, index) => {
                      return (<option key={index} value={item}>{item}</option>)

                    }) : ""
                  }
                </select>
                {/* {errors.area && <span className="form-error-dk">This field is required</span>} */}
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Pin Code *</label>

                {
                  pinCode.pincode != '' ?
                    <input type="text" className={`form-control ${errors.pinCode ? 'is-invalid' : ''}`} value={pinCode.pincode} name="pinCode" {...register("pinCode", { required: true })} readOnly />

                    : <input type="text" className="form-control" value='' name="pinCode" readOnly />
                }


              </div>


            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">City *</label>
                <input type="text" className={`form-control ${errors.city ? 'is-invalid' : ''}`} name="city" {...register("city", { required: true })} />
                {/* {errors.city && <span className="form-error-dk">This field is required</span>} */}
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Contact No</label>
                <input type="text" className={`form-control ${errors.contactNo ? 'is-invalid' : ''}`} name="contactNo" {...register("contactNo", { required: true })} />
                {/* {errors.contactNo && <span className="form-error-dk">This field is required</span>} */}
              </div>

            </div>
            <div className='row'>
              <div className="col">
                <label className="form-label-dk">Office Address *</label>
                <textarea className={`form-control ${errors.address ? 'is-invalid' : ''}`} name="address" {...register("address", { required: true })} />
                {/* {errors.address && <span className="form-error-dk">This field is required</span>} */}
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col'>

                <>
                  <button className="btn btn-warning" type="submit">Next Step</button>
                  {/* <button className="next_button" type="submit">Submit</button> */}

                  {/* <pre>{orgState.orgName}</pre> */}
                  <pre>{JSON.stringify(companyState)}</pre>

                </>


              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  )
}

export default CompanyDetailsForm
