import React from 'react'
import './EditTransportProgressBar.scss'

function EditTransportProgressBar({stepNo}) {
    const step = stepNo;
    console.log("Props", step);
    return (


        <div style={{marginTop:'0px'}}>
            <div id="multistepsform">
                <ul id="progressbar">
                    {/* <li className={`${step===1 ? "active" : ""}`}>Category Selection</li> */}
                    <li className={`${step===1 ? "active" : ""}`}>Organization Details</li>
                    <li className={`${step===2 ? "active" : ""}`}>Vehicle Detail</li>
                    <li className={`${step===3 ? "active" : ""}`}>Owner Details</li>
                    <li className={`${step===4 ? "active" : ""}`}>Driver Details</li>
                    <li className={`${step===5 ? "active" : ""}`}>Features</li>
                    <li className={`${step===6 ? "active" : ""}`}>Uploads</li>
                    <li className={`${step===7 ? "active" : ""}`}>Summary</li>
                </ul>
            </div>
        </div>


    )
}

export default EditTransportProgressBar
