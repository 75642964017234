import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { event } from 'jquery';
import { useForm } from 'react-hook-form';
import HotelCommon from './HotelCommon';
import { useDispatch } from 'react-redux';
import { hotelOrgDetails } from '../../../../redux/multi-step-form/hotel/step-one/hotelStepOneAction';

function HotelDetailsForm({getStep}) {

  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userToken = localStorage.getItem('loginToken');
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${userToken}`
        }
      };

      
      const { register, handleSubmit, watch, formState: { errors } } = useForm();

      const [currentStep, setCurrentStep] = useState(1);
      const [stateName, setStateName] = useState([]);
      const [districtName, setDistrictName] = useState([]);
      const [areaName, setAreaName] = useState([]);
      const [pinCode, setPinCode] = useState("");

      const [slectedState, setSelectedState] = useState("");
      const [slectedDistrict, setSelecteDistrict] = useState("");
      const [slectedArea, setSelectedArea] = useState("");
      const [failedResponse, setFailedResponse] = useState("");

      

      const getStateList = () =>{
        axios.get(baseUrl+"/api/v1/common/get/detail/by/state/master", axiosConfig).then((res) =>{
          
          if(res.data.status === 'OK'){
            console.log("Response for fetchdata",res.data);
          console.log("Response for fetchdata",res.data.status);
          setStateName(res.data.data);
          }else{
              setFailedResponse(res.data.msg) 
          }
          
         // console.log(baseUrl,"BASE URL")
         }).catch(err =>{
           console.log('Exception ==='+err);
         });
       }
  
       const stateChanged = (e) =>{
       
        setSelectedState(e.target.value);
        console.log('Selected State is ', e.target.value );
  
        setDistrictName([]);
        setAreaName([]);
        
        if(e.target.value !== ''){
          axios.get(`${baseUrl}/api/v1/common/get/detail/by/district/master?stateName=${e.target.value}`, axiosConfig).then((res) =>{
          
            if(res.data.status === 'OK'){
              console.log("Response for fetchdata",res.data);
            console.log("Response for fetchdata",res.data.status);
            setDistrictName(res.data.data);
            }else{
                setFailedResponse(res.data.msg) 
            }
            
           // console.log(baseUrl,"BASE URL")
           }).catch(err =>{
             console.log('Exception ==='+err);
           });
        }
        else{
          setDistrictName([]);
          setAreaName([]);
        }
        
       }
  
       const districtChanged = (e) =>{
        console.log("District is is =="+e.target.value);
        console.log("State is is is =="+stateName);
        setSelecteDistrict(e.target.value)
        setAreaName([]);
        if(e.target.value !=='' && stateName !== ''){
          axios.get(`${baseUrl}/api/v1/common/get/detail/by/area/master?stateName=${slectedState}&districtName=${e.target.value}`, axiosConfig).then((res) =>{
          
            if(res.data.status === 'OK'){
              console.log("Response for fetchdata",res.data);
            console.log("Response for fetchdata",res.data.status);
            setAreaName(res.data.data);
            }else{
                setFailedResponse(res.data.msg) 
            }
            
           // console.log(baseUrl,"BASE URL")
           }).catch(err =>{
             console.log('Exception ==='+err);
           });
        }
        else{
          setAreaName([]);
        }
       }
  
       
       const areaChanged = (e) =>{
        console.log("District is is =="+e.target.value);
        console.log("State is is is =="+stateName);
        setSelectedArea(e.target.value)
       // setAreaName([]);
        if(e.target.value !== '' && stateName !== '' && slectedDistrict != ''){
          axios.get(`${baseUrl}/api/v1/common/get/detail/by/pincode/master?stateName=${slectedState}&districtName=${slectedDistrict}&area=${e.target.value}`, axiosConfig).then((res) =>{
          
            if(res.data.status === 'OK'){
              console.log("Response for fetchdata",res.data);
            console.log("Response for fetchdata",res.data.status);
            setPinCode(res.data.data);
            }else{
                setFailedResponse(res.data.msg) 
            }
            
           // console.log(baseUrl,"BASE URL")
           }).catch(err =>{
             console.log('Exception ==='+err);
           });
        }
        else{
          setAreaName([]);
        }
       }
   
       const onSubmit = (data) => {

        console.log("Submitted data ===",data)
         dispatch(hotelOrgDetails(data));
         getStep(2);
        console.log("Current step ==="+currentStep)
        
      };

     useEffect(() =>{
      getStateList();
     
     }, [])

  return (
   
    <div>
    {/* <>
    {orgObject.orgName && <HotelCommon orgObject={orgObject} step={currentStep} />}
    </> */}
      <div className="col-lg-12 col-md-12" style={{ marginTop: "1%" }}>
        <div className="card-body p-4 p-lg-5 text-black" style={{ backgroundColor: "#fff",minHeight:'50vh' }}>


          <form onSubmit={handleSubmit(onSubmit)}>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Hotel / Marriage Hall Name *</label>
                <input type="text" className={`form-control ${errors.orgName ? 'is-invalid' : ''}`} name="orgName" {...register("orgName", { required: true })} />
                {/* {errors.orgName && <span className="form-error-dk">This field is required</span>} */}

              </div>
              <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">Select Star *</label>
              <select className={`form-select ${errors.star ? 'is-invalid' : ''}`} name='star' {...register("star", { required: true })}  >
            
            <option value=''>Select Star</option>
            <option value='7'>7 Star</option>
            <option value='6'>6 Star</option>
            <option value='5'>5 Star</option>
            <option value='4'>4 Star</option>
            <option value='3'>3 Star</option>
            <option value='2'>2 Star</option>
            <option value='1'>1 Star</option>
            <option value='other'>Other</option>
           
          
          </select>
              </div>

            </div>

           <div className="row">
              <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">GST No</label>
                <input type="text" className="form-control" name="gstNo" {...register("gstNo", { required: false })}/>
                <input type="hidden" className="form-control" name="tinNo" {...register("tinNo", { required: false })} />
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Registration No</label>
                <input type="text" className="form-control" name="regNo" {...register("regNo", { required: true })} />
              </div>

            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">State *</label>
                <select className={`form-select ${errors.state ? 'is-invalid' : ''}`} name='state' {...register("state", { required: true })}  onChange={stateChanged} >
            
                  <option value=''>Select State</option>
                 {
                  stateName.length > 0 ? stateName.map((item, index) =>{
                    return (<option key={index} value={item}>{item}</option>)
                    
                  }):""
                }
                
                </select>
                {/* {errors.state &&<span className="form-error-dk">This field is required</span>} */}
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">District *</label>
                <select className={`form-select ${errors.district ? 'is-invalid' : ''}`} name='district' {...register("district", { required: true })} onChange={districtChanged} >
                 
                  <option value=''>Select District</option>
                  {
                  districtName.length > 0 ? districtName.map((item, index) =>{
                    return (<option key={index} value={item}>{item}</option>)
                    
                  }):""
                }
                </select>
                
              </div>

            </div> 

             <div className="row">
            <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Area *</label>
                <select className={`form-select ${errors.area ? 'is-invalid' : ''}`} name='area' {...register("area", { required: true })} onChange={areaChanged} >
                
                  <option value=''>Select Area</option>
                  {
                  areaName.length > 0 ? areaName.map((item, index) =>{
                    return (<option key={index} value={item}>{item}</option>)
                    
                  }):""
                }
                </select>
               
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Pin Code *</label>
                
                {
                  pinCode.pincode != '' ?
                    <input type="text" className={`form-control ${errors.pinCode ? 'is-invalid' : ''}`} value={pinCode.pincode} name="pinCode" {...register("pinCode", { required: true })} readOnly />

                    : <input type="text" className="form-control" value='' name="pinCode" readOnly />
                }
                
                {/* {errors.pincode && <span className="form-error-dk">This field is required</span>} */}
              </div>
              

            </div> 

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">City *</label>
                <input type="text" className={`form-control ${errors.city ? 'is-invalid' : ''}`} name="city" {...register("city", { required: true })} />
               
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Contact No</label>
                <input type="text" className={`form-control ${errors.contactNo ? 'is-invalid' : ''}`} name="contactNo" {...register("contactNo", { required: true })} />
               
              </div>

            </div>
            <div className='row'>
            <div className="col">
                <label className="form-label-dk">Office Address *</label>
                <textarea className={`form-control ${errors.address ? 'is-invalid' : ''}`} name="address" {...register("address", { required: true })} />
                {/* {errors.address && <span className="form-error-dk">This field is required</span>} */}
              </div>
              </div> 
            <div className='row mt-5'>
              <div className='col'>
            
              <>
              <button className="btn btn-warning" type="submit">Next Step</button>
              {/* <button className="next_button" type="submit">Submit</button> */}
              </>            
             
             
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  )
}

export default HotelDetailsForm
