import React, { useEffect, useState } from 'react'
import CateringProgressBar from './CateringProgressBar'
import CateringOrgDetailsForm from './CateringOrgDetailsForm';
import CateringOwnerDetailsForm from './CateringOwnerDetailsForm';
import CateringFeatureForm from './CateringFeatureForm';
import CateringUploadsForm from './CateringUploadsForm';
import CateringSummaryForm from './CateringSummaryForm';

function CateringCommon() {
  

    const [currentStep, setCurrentStep] = useState(1);
    const [orgState, setOrgState] = useState({});
    const [ownerState, setOwnerState] = useState({});
    const [featureState, setFeatureState] = useState([{}]);
    const [uploadState, setUploadState] = useState({});

    const [hotelState, setHotelState] = useState({
      organization: '',
      owner:'',
      features:[{}],
      uploads:''
    })

  

  function getOrgData(data)
  {
    console.log("PASSED DATA FROM CHILD IS =="+data);
    setOrgState(data);
  }

  function getOwnerData(data)
  {
    console.log("PASSED DATA FROM CHILD IS =="+data);
    setOwnerState(data);
  }

  function getFeatureData(data)
  {
    console.log("PASSED DATA FROM CHILD IS =="+data);
    setFeatureState(data);
  }

  function getUploadedData(data)
  {
    console.log("PASSED DATA FROM CHILD IS =="+data);
    setUploadState(data);
  }


  function getSummaryData(data)
  {
    console.log("PASSED DATA FROM CHILD IS =="+data);
    setHotelState(
      {
        organization: orgState,
        owner:ownerState,
        features:featureState,
        uploads:uploadState
      }
    );
  }

  function getStep(step)
  {
    console.log("PASSED Step FROM CHILD IS =="+step);
    setCurrentStep(step);
  }

    // if(step != null){
    //   setCurrentStep(step);
    //   console.log("PASSED OBJECT IS =="+orgObject)
    // }


    useEffect(() =>{
      setHotelState(
        {
          organization: orgState,
          owner:ownerState,
          features:featureState,
          uploads:uploadState
        }
      );
    }, [orgState, ownerState, featureState,uploadState])
  return (
    <div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div style={{marginLeft:'15%'}}>
            <CateringProgressBar stepNo={currentStep} />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-8 col-md-8 mx-auto' style={{ backgroundColor: '#fff', marginTop: '1%' }}>
            {/* <form enctype="multipart/form-data"> */}
              {(() => {
                switch (currentStep) {
                  case 1:
                    return <CateringOrgDetailsForm getData={getOrgData} getStep={getStep} />
                 
                  case 2:
                    return <CateringOwnerDetailsForm getData={getOwnerData} getStep={getStep} />
                
                  case 3:
                    return <CateringFeatureForm getData={getFeatureData} getStep={getStep}/>
                  case 4:
                    return <CateringUploadsForm getData={getUploadedData} getStep={getStep} />
                  case 5:
                    return <CateringSummaryForm getData={hotelState} getStep={getStep} />

                }
              })()}
              
          </div>
         
        </div>
      </div>
    </div>
  )
}

export default CateringCommon
