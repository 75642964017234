import { SET_HOTEL_STEP_ONE } from "../../../constant"

export const hotelStepOneData = (data = [], action) => {
    switch (action.type) {
            case SET_HOTEL_STEP_ONE:
                console.warn("SET_HOTEL_STEP_ONE condition ", action)
                return [...action.data]

                // case SET_HOTEL_STEP_TWO:
                // console.warn("SET_HOTEL_STEP_TWO condition ", action)
                // return [...action.data]

                // case SET_HOTEL_STEP_THREE:
                // console.warn("SET_HOTEL_STEP_THREE condition ", action)
                // return [...action.data]

                // case SET_HOTEL_STEP_FOUR:
                // console.warn("SET_HOTEL_STEP_FOUR condition ", action)
                // return [...action.data]

                // case SET_HOTEL_STEP_FIVE:
                // console.warn("SET_HOTEL_STEP_FIVE condition ", action)
                // return [...action.data]
        default:
            return data
    }
}