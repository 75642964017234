import React, { useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form';

function MakeupFeatureForm(props) {

  const [currentStep, setCurrentStep] = useState(props.step);
  const [featureState, setFeatureState] = useState([{
    feature:''
  }])
  const { register, handleSubmit, watch, control, formState: { errors } } = useForm({
    defaultValues: {
      features: [{ name: "" }]
    }
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "features", // unique name for your Field Array
  });


  const backStep = () => {
    props.getStep(2);
  }
  const onSubmit = (data) => {
    console.log("Submitted data ===", data)
    localStorage.setItem('features', JSON.stringify(data));
    props.getData(data);
    props.getStep(4);

    console.log("Current step ===" + currentStep)

  };
  return (
    <div>
      <div className="col-lg-12 col-md-12" style={{ marginTop: "1%" }}>
        <div className="card-body p-4 p-lg-5 text-black" style={{ backgroundColor: "#fff", minHeight: '50vh' }}>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
             
                  {fields.map((field, index) => (
                     <div className="col-lg-12 col-sm-12">
                     <label className="form-label-dk">Feature {index + 1}</label>
                     <div className='d-flex'>
                    <input
                    className={`form-control ${errors.features?.[index] ? 'is-invalid' : ''}`}
                      key={field.id}
                      {...register(`features.${index}.name`, { required: true })}
                    />
                    
                    <button className='btn btn-danger' style={{marginLeft:'3px'}} onClick={() => remove(index)}>
                <i class='bx bx-trash' ></i>
                </button>
                     </div>
                   </div>
                  ))}
               
               <div className="col-lg-12 col-sm-12">
              <button className="btn btn-primary mt-3" type='button' onClick={() => append({ feature: '' })} style={{width:'100%'}}>Add Feature&nbsp; <i class='bx bxs-plus-circle'></i></button>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col'>
                <>
                  <button className="btn btn-warning" type='button' onClick={backStep}>Back</button>&nbsp;
                  <button className="btn btn-warning" type="submit">Next Step</button>
                </>

              </div>
            </div>
          </form>


        </div>
      </div>
    </div>
  )
}

export default MakeupFeatureForm
