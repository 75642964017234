import {configureStore} from '@reduxjs/toolkit'

import createSagaMiddleware from 'redux-saga';
import hoteStepOneSaga from '../multi-step-form/hotel/step-one/hotelStepOneSaga';
import rootReducer from '../reducer/rootReducer';
import entertainmentSaga from '../saga/entertainmentSaga';

import makeupSaga from '../saga/makeupSaga';
import transportSaga from '../saga/transportSaga';

// const store = createStore(rootReducer);
const sagaMiddleware = createSagaMiddleware();
const store  = configureStore({
    reducer:rootReducer,
    middleware:()=>[sagaMiddleware]
});

sagaMiddleware.run(transportSaga);
sagaMiddleware.run(makeupSaga);
sagaMiddleware.run(entertainmentSaga);


sagaMiddleware.run(hoteStepOneSaga)

export default store;