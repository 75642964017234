import React, { useEffect, useState } from 'react'
import TransportCommon from './transport/TransportCommon'
import HotelCommon from './hotels/HotelCommon'
import EntertainmentCommon from './entertainment/EntertainmentCommon';
import MakeupCommon from './makeup/MakeupCommon';
import CateringCommon from './catering/CateringCommon';

function AdPost() {

    let storedCat = localStorage.getItem('category');

    const [category, setCategory] = useState(storedCat);
   
useEffect(() =>{
    setCategory(storedCat)
    console.log('SELECTED CATEGORY IS =='+category)
},[])
  return (
    <>
      {
        category == 1?<TransportCommon />:""
      }
      {
        category == 3?<MakeupCommon />:""
      }
       {
        category == 7?<EntertainmentCommon />:""
      }
      {
        category == 8?<HotelCommon />:""
      }
      {
        category == 9?<CateringCommon />:""
      }
            </>
      
  )
}

export default AdPost
