import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';


function CategoryForm() {

    const navigate = useNavigate();

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const userToken = localStorage.getItem('loginToken');
    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
          "Authorization": `Bearer ${userToken}`
      }
    };
    const [currentCategory, setCurrentCategory] = useState([""]);
    const [currentSubCategory, setCurrentSubCategory] = useState([""]);

    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [failedResponse, setFailedResponse] = useState("");

    const [showBtn, setShowBtn] = useState(false);
    

    const getCategories = ()=>{

        axios.get(baseUrl+"/api/v1/admin/category/list", axiosConfig).then((res) =>{
          console.log("Response for fetchdata",res.data.data);
          console.log("Response for fetchdata",res.data.status);
          
          if(res.data.status === 'OK'){
            setCategory(res.data.data);
          console.log("CATEGORIES IS ==", category);
          }else{
              setFailedResponse(res.data.msg) 
          }
          
         // console.log(baseUrl,"BASE URL")
         }).catch(err =>{
           console.log('Exception ==='+err);
         });
      }

      const getSubCategories = (categoryId)=>{

        axios.get(`${baseUrl}/api/v1/admin/sub-category/list?id=${categoryId}`, axiosConfig).then((res) =>{
          console.log("Response for fetchdata",res.data.data);
          console.log("Response for fetchdata",res.data.status);
          
          if(res.data.status === 'OK'){
          setSubCategory(res.data.data);
          console.log("sub CATEGORIES IS ==", subCategory);
          }else{
              setFailedResponse(res.data.msg) 
          }
          
         // console.log(baseUrl,"BASE URL")
         }).catch(err =>{
           console.log('Exception ==='+err);
         });
      }
    
      const categoryChanged = (event) =>{
        setShowBtn(false);
        if(event.target.value != ''){
          console.log('Category changed   '+event.target.value);
          setCurrentCategory(event.target.value);
          localStorage.setItem('category', event.target.value);
          getSubCategories(event.target.value);
          
        }else{

          setSubCategory([]);
        }
        
      }

      const suBategoryChanged = (event) =>{
        if(event.target.value != ''){
        console.log('Sub Category changed   '+event.target.value);
        setCurrentSubCategory(event.target.value);
        localStorage.setItem('subCategory', event.target.value);
        setShowBtn(true);

        console.log('HIDEBTN ==', showBtn);
        }else{
          setShowBtn(false);
         console.log('HIDEBTN ==', showBtn);
        }
       
      }

      const categorySelected = () =>{
        console.log("category Selected"+currentCategory);
       navigate('/sp/register/ad')
      }
    
      useEffect  (()=>{
        getCategories();
       // setSubCategory(1);
       localStorage.removeItem('driver');
      localStorage.removeItem('vehicle');
      localStorage.removeItem('owner');
      localStorage.removeItem('category');
      localStorage.removeItem('features');
      localStorage.removeItem('galleryImgPath');
      localStorage.removeItem('featuredImg');
      localStorage.removeItem('organization');
      localStorage.removeItem('subCategory');
      localStorage.removeItem('featuredImgPath');
      localStorage.removeItem('uploaded');
      },[])
  return (
    <div>
      <div className='container'>
        <div className='row'>
        <div className="col-lg-8 col-md-8 mx-auto" style={{marginTop:"1%"}}>
                  <div className="card-body p-4 p-lg-5 text-black" style={{backgroundColor:"#fff"}}>
    
                  
              <form>
              <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Category *</label>
                <select className="form-select" name='category' onChange={categoryChanged} >
                 
                  <option value="">Select Category</option>
                        {
                           category.length > 0 ? category.map((item, index)=>{
                            return (<option value={item.id} key={index}>{item.categoryLabel}</option>)
                           })
                           :""
                        }
                
                </select>
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Sub Category *</label>
                <select className="form-select" name='subcategory'  onChange={suBategoryChanged}>
                <option value="">Select Sub Category</option>
                         {
                           subCategory.length > 0 ? subCategory.map((item, index)=>{
                            return (<option value={item.id} key={index}>{item.subCategoryLabel}</option>)
                           })
                           :""
                        }
                </select>
              </div>

            </div>
            <div className="row mt-5">
              <div className="col-lg-12 col-sm-12 ">
              {showBtn && <button className="btn btn-warning" type="button" onClick={categorySelected}>Next</button>}
                </div>
              </div>
              </form>
    
                  </div>

             
                </div>
        </div>
      </div>
    </div>
  )
}

export default CategoryForm
