import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';

function CateringUploadsForm(props) {

  const [currentStep, setCurrentStep] = useState(props.step);
  const [uploadState, setUploadState] = useState();
  const [featuredImageState, setFeaturedImageState] = useState();
  const [galleryState, setGallaryState] = useState();

  const [fetauredImg, setFeaturedImg] = useState();
  const [galleryImg, setGalleryImg] = useState();

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: uploadState
  });

  const backStep = () =>{
    props.getStep(3);
  }
  const onSubmit = (data) => {
  
    localStorage.setItem('uploaded', JSON.stringify(data));
    console.log("Featured Image data ===",data.featuredImage[0].name)
    console.log("Gallery Image data ===",data.gallery[0].name)
    
     const imgData = {
      featuredImage: fetauredImg,
      gallery: galleryImg
     }
    props.getData(imgData);
    props.getStep(5);
  
    console.log("Current step ==="+currentStep)
    
  };

  const featureImageChanged = (e) =>{

    console.log("featured image =="+e.target.files[0]);
     setFeaturedImg(e.target.files[0]);
     localStorage.setItem('featuredImg', e.target.files[0]);
      setFeaturedImageState( URL.createObjectURL(e.target.files[0]))
      localStorage.setItem('featuredImgPath', URL.createObjectURL(e.target.files[0]));
  }

  const galleryChanged = (e) =>{

    console.log("gallerry image =="+e);
    setGalleryImg(e.target.files[0]);
    setGallaryState(URL.createObjectURL(e.target.files[0]))
    localStorage.setItem('galleryImgPath', URL.createObjectURL(e.target.files[0]));
  }

  useEffect(()=>{
   //const objectUrl = URL.createObjectURL(selectedFile)
   //setPreview(objectUrl)
  })
  return (
    <div>
      <div className="col-lg-12 col-md-12" style={{ marginTop: "1%" }}>
        <div className="card-body p-4 p-lg-5 text-black" style={{ backgroundColor: "#fff", minHeight: '50vh' }}>


         <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
         <div className="row">
         <h3 style={{color:'#ff9800', fontSize:'13px'}}>Feature Image</h3>
            <section className='orgSection' style={{ border: '1px solid #ccc', padding:'1%' }}>
              <div className='row'>
            <div className="col-lg-4 col-sm-12">
              {/* <label className="form-label-dk"> Feature Image</label> */}
              <input type="file" accept="image/*" className="form-control" name="featuredImage" {...register("featuredImage", { required: true })} onChange={featureImageChanged} />
              {errors.featuredImage && <span className="form-error-dk">This field is required</span>}
            </div>
            <div className="col-lg-8 col-sm-12">
              {featuredImageState && <img src={featuredImageState} style={{width:'150px', height: '200px'}} />}
            </div>
            </div>
            </section>
            {/* <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">Vehicle Documents</label>
              <input type="file" className="form-control" name="vehicleDocs" />
            </div> */}

          </div>

          {/* <div className="row">
            <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk"> Pollution Certificate</label>
              <input type="file" className="form-control" name="pollutionCertificate" />
            </div>
            <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">Driving Licence</label>
              <input type="file" className="form-control" name="driverLicence" />
            </div>

          </div> */}
          <div className="row">
          <h3 style={{color:'#ff9800', fontSize:'13px', paddingTop:'2%'}}>Gallery Images</h3>
            <section className='orgSection' style={{ border: '1px solid #ccc', padding:'1%', marginTop:'0%' }}>
           <div className='row'>
            <div className="col-lg-4 col-sm-12">
              {/* <label className="form-label-dk"> Gallery Image</label> */}
              <input type="file" accept="image/*" className="form-control" name="gallery" {...register("gallery", { required: true })} onChange={galleryChanged} multiple/>
              {errors.gallery && <span className="form-error-dk">This field is required</span>}
            </div>
            <div className="col-lg-8 col-sm-12">
           {galleryState && <img src={galleryState} style={{width:'150px', height: '200px'}} />} 
            </div>
            </div>
            </section>
          </div>

          <div className='row mt-5'>
            <div className='col'>
              <>
                <button className="btn btn-warning" type='button' onClick={backStep}>Back</button>&nbsp;
                <button className="btn btn-warning" type="submit">Next Step</button>
              </>

            </div>
          </div>
         </form>
        </div>
      </div>
    </div>
  )
}

export default CateringUploadsForm
