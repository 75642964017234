import React, { useEffect, useState } from 'react'
import HotelDetailsForm from './HotelDetailsForm'
import HotelProgressBar from './HotelProgressBar'
import HotelOwnerDetailsForm from './HotelOwnerDetailsForm';
import HotelFeatureForm from './HotelFeatureForm';
import HotelUploadsForm from './HotelUploadsForm';
import HotelSummaryForm from './HotelSummaryForm';

function HotelCommon() {
  

    const [currentStep, setCurrentStep] = useState(1);
    const [orgState, setOrgState] = useState({});
    const [ownerState, setOwnerState] = useState({});
    const [featureState, setFeatureState] = useState([{}]);
    const [uploadState, setUploadState] = useState({});

    const [hotelState, setHotelState] = useState({
      organization: '',
      owner:'',
      features:[{}],
      uploads:''
    })

    const [featuredImageState, setFeaturedImageState] = useState(null);
    const [galleryImageState, setGalleryImageState] = useState(null);

  function getOrgData(data)
  {
    console.log("PASSED DATA FROM CHILD IS =="+data);
    setOrgState(data);
  }

  function getOwnerData(data)
  {
    console.log("PASSED DATA FROM CHILD IS =="+data);
    setOwnerState(data);
  }

  function getFeatureData(data)
  {
    console.log("PASSED DATA FROM CHILD IS =="+data);
    setFeatureState(data);
  }

  function getUploadedData(data) {
    console.log("UPLOAD DATA FROM CHILD UPLOAD COMPONENTS ==" + data);
  //  setFeaturedImageState(data.featuredImage[0].name)
    setUploadState({
      featuredImage: data.featuredImage.name,
      gallery:[data.gallery.name]
    });

    setFeaturedImageState(data.featuredImage);
    setGalleryImageState(data.gallery);
  }


  function getSummaryData(data)
  {
    console.log("PASSED DATA FROM CHILD IS =="+data);
    setHotelState(
      {
        organization: orgState,
        owner:ownerState,
        // features:featureState,
        uploads:uploadState
      }
    );
  }

  function getStep(step)
  {
    console.log("PASSED Step FROM CHILD IS =="+step);
    setCurrentStep(step);
  }

    // if(step != null){
    //   setCurrentStep(step);
    //   console.log("PASSED OBJECT IS =="+orgObject)
    // }


    useEffect(() =>{
      setHotelState(
        {
          organization: orgState,
          owner:ownerState,
        //  features:featureState,
          uploads:uploadState,
        category: JSON.parse(localStorage.getItem('category')),
        subCategory: JSON.parse(localStorage.getItem('subCategory')),
        }
      );
    }, [orgState, ownerState, featureState,uploadState])
  return (
    <div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <div style={{marginLeft:'15%'}}>
            <HotelProgressBar stepNo={currentStep} />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-8 col-md-8 mx-auto' style={{ backgroundColor: '#fff', marginTop: '1%' }}>
            {/* <form enctype="multipart/form-data"> */}
              {(() => {
                switch (currentStep) {
                  case 1:
                    return <HotelDetailsForm getStep={getStep}/>
                 
                  case 2:
                    return <HotelOwnerDetailsForm getStep={getStep} />
                
                  case 3:
                    return <HotelFeatureForm getStep={getStep} />
                  case 4:
                    return <HotelUploadsForm  getStep={getStep} />
                  case 5:
                    return <HotelSummaryForm getStep={getStep} />

                }
              })()}
             
          </div>
         
        </div>
      </div>
    </div>
  )
}

export default HotelCommon
