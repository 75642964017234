import React, { useEffect, useState } from 'react'

import CompanyDetailsForm from './EditCompanyDetailsForm';
import DriverDetailsForm from './EditDriverDetailsForm';
import FeatureForm from './EditTransportFeatureForm';
import OwnerDetailsForm from './EditTransportOwnerDetailsForm';
import ProgressBar from './EditTransportProgressBar';
import SummaryForm from './EditTransportSummaryForm';
import UploadsForm from './EditTransportUploadsForm';
import VehicleDetailsForm from './EditTransportDetailsForm';
import EditTransportProgressBar from './EditTransportProgressBar';
import EditCompanyDetailsForm from './EditCompanyDetailsForm';
import EditTransportDetailsForm from './EditTransportDetailsForm';
import EditTransportOwnerDetailsForm from './EditTransportOwnerDetailsForm';
import EditDriverDetailsForm from './EditDriverDetailsForm';
import EditTransportFeatureForm from './EditTransportFeatureForm';
import EditTransportUploadsForm from './EditTransportUploadsForm';
import EditTransportSummaryForm from './EditTransportSummaryForm';
import { useParams } from 'react-router-dom';
import axios from 'axios';


function EditTransportCommon() {

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [featureImage, setFeatureImage] = useState();

  const { type, id } = useParams();
 const orgInitial = {
  orgName: "",
  gstNo: "",
  tinNo: "",
  regNo: "",
  state: "",
  district: "",
  city: "",
  address: "",
  pinCode: "",
  contactNo: ""
}
  const [orgState, setOrgState] = useState({});
  const [vehicleState, setVehicleState] = useState({});
  const [ownerState, setOwnerState] = useState({});
  const [driverState, setDriverState] = useState({});
  const [featureState, setFeatureState] = useState([]);
  const [uploadState, setUploadState] = useState({
    id:'',
    featuredImage:'',
    gallery:[''],
    vehicleDocs:'',
    pollutionCertificate:'',
    driverLicence:''


  });
  const [featuredImageState, setFeaturedImageState] = useState(null);
  const [galleryImageState, setGalleryImageState] = useState(null);

  const [transportState, setTransportState] = useState({
    id:'',
    brand: '',
    model: '',
    launchYear: '',
    vehicleColour: '',
    vehicleNo: '',
    organization: '',
    owner: '',
    driver: '',
    features: [{}],
    uploads: '',
    featuredImage: '',
    gallery: [''],
    category:'',
    subCategory: ''
  })


  const fetchData = () => {

  
    axios.get(`${baseUrl}/api/v1/transport/list?trId=${id}`).then((res) => {
      console.log("Response for fetchdata", res.data.data);
      if(res.data.status === 'OK'){
        setTransportState(res.data.data);
        setOrgState(res.data.data?.organization);
        setOwnerState(res.data.data?.owner)
        setDriverState(res.data.data?.driver)
        setFeatureImage(res.data.data.uploads.featuredImage);
        setUploadState(res.data.data.uploads?.uploads)
        setFeatureState(res.data.data.uploads.features)
      console.log("RESPONSE IS   ==", transportState.uploads);
      console.log("FEATURE IMAGE IS   ==" + featureImage);
      console.log(baseUrl, "BASE URL")
      setLoading(false);
      }
      
    }).catch(err => {
      console.log("ERROR    ", err);
      console.log("BASE URL    ", baseUrl);
    });

  }


  function getOrgData(data) {
    console.log("PASSED DATA FROM CHILD IS ==" + data);
    setOrgState(data);
  }
  function getVehicleData(data) {
    console.log("PASSED DATA FROM CHILD IS ==" + data);
    setVehicleState(data);
  }

  function getOwnerData(data) {
    console.log("PASSED DATA FROM CHILD IS ==" + data);
    setOwnerState(data);
  }

  function getDriverData(data) {
    console.log("PASSED DATA FROM CHILD IS ==" + data);
    setDriverState(data);
  }

  function getFeatureData(data) {
    console.log("PASSED FEATURED DATA FROM CHILD IS ==" + data);
    setFeatureState(data);
  }
  function getUploadData(data) {
    console.log("UPLOAD DATA FROM CHILD UPLOAD COMPONENTS ==" + data);
  //  setFeaturedImageState(data.featuredImage[0].name)
    setUploadState({
      featuredImage: data.featuredImage.name,
      gallery:[data.gallery.name]
    });

    setFeaturedImageState(data.featuredImage);
    setGalleryImageState(data.gallery);
  }

  function getSummaryData(data) {
    console.log("Summary Data ==" + data);
    // setTransportState(
    //   {
    //     id: vehicleState?.id,
    //     brand: vehicleState?.brand,
    //     model: vehicleState?.model,
    //     launchYear: vehicleState?.launchYear,
    //     vehicleColour: vehicleState?.vehicleColour,
    //     vehicleNo: vehicleState?.vehicleNo,
    //     organization: orgState,
    //     owner: ownerState,
    //     driver: driverState,
    //     features: featureState,
    //     uploads: uploadState,
    //     category: vehicleState?.category,
    //     subCategory: vehicleState?.subCategory,
        
    //   }
    // );
  }

  function getStep(step) {
    console.log("PASSED Step FROM CHILD IS ==" + step);
    setCurrentStep(step);
  }

useEffect(()=>{
  fetchData();

  // setTransportState(
  //   {
  //     id: vehicleState?.id,
  //     brand: vehicleState?.brand,
  //     model: vehicleState?.model,
  //     launchYear: vehicleState?.launchYear,
  //     vehicleColour: vehicleState?.vehicleColour,
  //     vehicleNo: vehicleState?.vehicleNo,
  //     organization: orgState,
  //     owner: ownerState,
  //     driver: driverState,
  //     features: featureState,
  //     uploads: uploadState,
  //     category: vehicleState?.category,
  //     subCategory: vehicleState?.subCategory,
      
  //   }
  // );
},[])



  useEffect(() => {

    
    // setTransportState(
    //   {
    //     id: vehicleState?.id,
    //     brand: vehicleState?.brand,
    //     model: vehicleState?.model,
    //     launchYear: vehicleState?.launchYear,
    //     vehicleColour: vehicleState?.vehicleColour,
    //     vehicleNo: vehicleState?.vehicleNo,
    //     organization: orgState,
    //     owner: ownerState,
    //     driver: driverState,
    //     features: featureState,
    //     uploads: uploadState,
    //     category: vehicleState?.category,
    //     subCategory: vehicleState?.subCategory,
        
    //   }
    // );


  }, [vehicleState, orgState, ownerState, driverState, featureState, uploadState])
  return (
    <div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <EditTransportProgressBar stepNo={currentStep} />
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-10 col-md-10 mx-auto' style={{ backgroundColor: '#fff', marginTop: '1%' }}>
            {/* <form enctype="multipart/form-data" onSubmit={handleSubmit(onSubmit)}> */}
            {(() => {
              switch (currentStep) {
                case 1:
                  return <EditCompanyDetailsForm getData={getOrgData} getStep={getStep} currentData={orgState} />
                case 2:
                  return <EditTransportDetailsForm getData={getVehicleData} getStep={getStep} currentData={transportState}  />
                case 3:
                  return <EditTransportOwnerDetailsForm getData={getOwnerData} getStep={getStep} currentData={ownerState} />
                case 4:
                  return <EditDriverDetailsForm getData={getDriverData} getStep={getStep} currentData={driverState} />
                case 5:
                  return <EditTransportFeatureForm getData={getFeatureData} getStep={getStep} />
                case 6:
                  return <EditTransportUploadsForm getData={getUploadData} getStep={getStep}  />
                case 7:
                  return <EditTransportSummaryForm getData={getSummaryData} getStep={getStep} />
                  default:
                    return <EditCompanyDetailsForm getData={getOrgData} getStep={getStep} stateData={orgState}/>

              }
            })()}

            {/* </form> */}
          </div>
          {/* <pre>
            {JSON.stringify(watch(), null, 2)}
          </pre> */}
        </div>
      </div>

    </div>
  )
}

export default EditTransportCommon
