import {combineReducers} from 'redux'
import { hotelStepOneData } from '../multi-step-form/hotel/step-one/hotelStepOneReducer'
import { entertainmentData } from './entertainmentReducer'
import { makeupData } from './makeupReducer'
import { transportData } from './transportReducer'

export default combineReducers({
   transportData: transportData,
   makeupData: makeupData,
   entertainmentData: entertainmentData,

   hotelStepOneData: hotelStepOneData
   

})