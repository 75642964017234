import React, { useEffect, useState } from 'react'

import CompanyDetailsForm from './CompanyDetailsForm';
import DriverDetailsForm from './DriverDetailsForm';
import FeatureForm from './FeatureForm';
import OwnerDetailsForm from './OwnerDetailsForm';
import ProgressBar from './ProgressBar';
import SummaryForm from './SummaryForm';
import UploadsForm from './UploadsForm';
import VehicleDetailsForm from './VehicleDetailsForm';


function TransportCommon() {

  const [currentStep, setCurrentStep] = useState(1);
 
  const [orgState, setOrgState] = useState(
    {
      "orgName": "Ruby Transport Company",
      "gstNo": "12345",
      "tinNo": "95855557",
      "regNo": "dddd@gmail.com",
      "state": "Uttar Pradesh",
      "district": "Basti",
      "city": "Basti",
      "address": "Aksh",
      "pinCode": "272001",
      "contactNo": "858857577577"
    }
  );
  const [vehicleState, setVehicleState] = useState({});
  const [ownerState, setOwnerState] = useState({});
  const [driverState, setDriverState] = useState({});
  const [featureState, setFeatureState] = useState([]);
  const [uploadState, setUploadState] = useState({
    featuredImage:'',
    gallery:[''],
    vehicleDocs:'',
    pollutionCertificate:'',
    driverLicence:''


  });
  const [featuredImageState, setFeaturedImageState] = useState(null);
  const [galleryImageState, setGalleryImageState] = useState(null);

  

  const [transportState, setTransportState] = useState({
    brand: '',
    model: '',
    launchYear: '',
    vehicleColour: '',
    vehicleNo: '',
    organization: '',
    owner: '',
    driver: '',
    features: [{}],
    uploads: '',
    featuredImage: '',
    gallery: [''],
    category:'',
    subCategory: ''
  })


  function getOrgData(data) {
    console.log("PASSED DATA FROM CHILD IS ==" + data);
    setOrgState(data);
  }
  function getVehicleData(data) {
    console.log("PASSED DATA FROM CHILD IS ==" + data);
    setVehicleState(data);
  }

  function getOwnerData(data) {
    console.log("PASSED DATA FROM CHILD IS ==" + data);
    setOwnerState(data);
  }

  function getDriverData(data) {
    console.log("PASSED DATA FROM CHILD IS ==" + data);
    setDriverState(data);
  }

  function getFeatureData(data) {
    console.log("PASSED FEATURED DATA FROM CHILD IS ==" + data);
    setFeatureState(data);
  }
  function getUploadData(data) {
    console.log("UPLOAD DATA FROM CHILD UPLOAD COMPONENTS ==" + data);
  //  setFeaturedImageState(data.featuredImage[0].name)
    setUploadState({
      featuredImage: data.featuredImage.name,
      gallery:[data.gallery.name]
    });

    setFeaturedImageState(data.featuredImage);
    setGalleryImageState(data.gallery);
  }

  function getStep(step) {
    console.log("PASSED Step FROM CHILD IS ==" + step);
    setCurrentStep(step);
  }



  useEffect(() => {
    setTransportState(
      {
        brand: vehicleState.brand,
        model: vehicleState.model,
        launchYear: vehicleState.launchYear,
        vehicleColour: vehicleState.vehicleColour,
        vehicleNo: vehicleState.vehicleNo,
        organization: orgState,
        owner: ownerState,
        driver: driverState,
        // features: featureState,
        uploads: uploadState,
        category: JSON.parse(localStorage.getItem('category')),
        subCategory: JSON.parse(localStorage.getItem('subCategory')),
        
      }
    );


  }, [vehicleState, orgState, ownerState, driverState, featureState, uploadState])
  return (
    <div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <ProgressBar stepNo={currentStep} />
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-8 col-md-8 mx-auto' style={{ backgroundColor: '#fff', marginTop: '1%' }}>
            {/* <form enctype="multipart/form-data" onSubmit={handleSubmit(onSubmit)}> */}
            {(() => {
              switch (currentStep) {
                case 1:
                  return <CompanyDetailsForm getData={getOrgData} getStep={getStep} />
                case 2:
                  return <VehicleDetailsForm getData={getVehicleData} getStep={getStep} />
                case 3:
                  return <OwnerDetailsForm getData={getOwnerData} getStep={getStep} />
                case 4:
                  return <DriverDetailsForm getData={getDriverData} getStep={getStep} />
                case 5:
                  return <FeatureForm getData={getFeatureData} getStep={getStep} />
                case 6:
                  return <UploadsForm getData={getUploadData} getStep={getStep} />
                case 7:
                  return <SummaryForm getData={transportState} getStep={getStep} featuredImage={featuredImageState} galleryImage={galleryImageState} />
                  default:
                    return <CompanyDetailsForm getData={getOrgData} getStep={getStep} stateData={orgState}/>

              }
            })()}

            {/* </form> */}
          </div>
          {/* <pre>
            {JSON.stringify(watch(), null, 2)}
          </pre> */}
        </div>
      </div>

    </div>
  )
}

export default TransportCommon
