import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useForm } from 'react-hook-form';

function EditTransportDetailsForm({ getData, getStep, currentData }) {

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userToken = localStorage.getItem('loginToken');
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${userToken}`
        }
      };
      const [currentStep, setCurrentStep] = useState(2);

      const [brands, setBrands] = useState([]);
      const [models, setModels] = useState([]);
      const [selectedbrand, setSelectedBrand] = useState("");
      const [transportState, setTransportState] = useState(currentData);
  

      const { register, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues:transportState
      });

      const fetchBrandData = () =>{
        axios.get(`${baseUrl}/api/v1/transport/brands`).then((res) =>{
         console.log("Response",res.data.data);
         if(res.data.status === 'OK'){
          setBrands(res.data.data);
         }
         
        }).catch(err =>{
          console.log("ERROR    ",err);
        });
        
      }

      const brandChanged = (e)=>{
        setModels([])
        console.log("Brand changed"+e.target.value)
        setSelectedBrand(e.target.value)
        fetchModelData(e.target.value);
      }

      const fetchModelData = (brandid) =>{
        axios.get(`${baseUrl}/api/v1/transport/models?brandId=${brandid}`).then((res) =>{
         console.log("Response",res.data.data);
         if(res.data.status === 'OK'){
          setModels(res.data.data);
         }
         
        }).catch(err =>{
          console.log("ERROR    ",err);
        });
        
      }

      const backStep = () =>{
        getStep(1);
       // props.getData();
      }
      const onSubmit = (data) => {
        localStorage.setItem('vehicle', JSON.stringify(data))
        console.log('SUBMITTED TRANSPORT DATA IS ==',data);
        console.log('CURRENT TRANSPORT DATA IS ==',currentData);
        getData(data);
        getStep(3);
        console.log("Current step ==="+currentStep)
        
      };

      useEffect(()=>{
        getStep(2)
        fetchBrandData();
        
      },[])
  return (
    <div>
       <div className="col-lg-12 col-md-12" style={{ marginTop: "1%" }}>
        <div className="card-body p-4 p-lg-5 text-black" style={{ backgroundColor: "#fff", minHeight:'50vh' }}>


          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Brand *</label>
                <select className="form-select" name='brand' {...register("brand", { required: false })} onChange={brandChanged} >
                  <option value=''>Select Brand</option>
                 {
                  brands.length > 0 ? brands.map((item, index) =>{
                    return (<option key={index} value={item.brandId}>{item.brandLabel}</option>)
                    
                  }):""
                }
                </select>
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Model *</label>
                <select className="form-select" name='model' {...register("model", { required: false })}>
                  <option value=''>Select Model</option>
                  {
                  models.length > 0 ? models.map((item, index) =>{
                    return (<option key={index} value={item.modelId}>{item.modelLabel}</option>)
                    
                  }):""
                }
                </select>
              </div>

            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
              <label className="form-label-dk">Vehicle Colour *</label>
                <select className="form-select" name='vehicleColour' {...register("vehicleColour", { required: false })}>
                  <option value=''>Select Colour</option>
                 <option value='White'>White</option>
                 <option value='Silver'>Silver</option>
                 <option value='Red'>Red</option>
                 <option value='Black'>Black</option>
                 <option value='Blue'>Blue</option>
                </select>
              </div>
              <div className="col-lg-6 col-sm-12">
                <label className="form-label-dk">Model Year (ex: 2020)</label>
                <input type="number" className="form-control" name="launchYear" {...register("launchYear", { required: false })}  />
              </div> 

            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
              <label className="form-label-dk">Vehicle Number</label>
              <input type="text" className="form-control" name="vehicleNo" {...register("vehicleNo", { required: false })} style={{textTransform: 'uppercase'}}  />
              </div>
              </div>
            <div className='row mt-5'>
              <div className='col'>
             <>
                <button className="btn btn-warning" type='button' onClick={backStep}>Back</button>&nbsp;
                <button className="btn btn-warning" type="submit">Next Step</button>
              </>
             
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
  )
}

export default EditTransportDetailsForm
