import React, { useState, useEffect } from 'react'
import Sidebar from './sidebar/TransportSidebar'
import featureImg1 from '../../images/scorpio.webp'
import featureImg2 from '../../images/brezza.webp'
import './../css/ListingPage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import StarRating from '../../utils/StarRating'
import { FaPhoneSquareAlt, FaUserAlt } from 'react-icons/fa';
import { GiPathDistance } from 'react-icons/gi';
import { MdAdminPanelSettings, MdLocationOn, MdOutlineFormatListNumberedRtl } from "react-icons/md";
import QuoteModal from '../../utils/QuoteModal'
import axios from 'axios';
import Pagination from '../../utils/Pagination'
import { paginate } from '../../utils/Utils'
import Loader from '../../utils/Loader'
import BreadCrumb from '../../utils/BreadCrumb'
import { useDispatch, useSelector } from 'react-redux'
import { transportList } from '../../redux/action/transportAction'

export default function Transports() {



  const [openModal, setModal] = useState(false);
  const [serviceId, setServiceId] = useState(0);
  const [currentPage, setCurrentPage] = useState(paginate);
  const showModal = (modalState, serviceId) => {
    setModal(modalState);
    setServiceId(serviceId);

    console.log("modal state " + modalState + " serviceId ==" + serviceId);
    // <QuoteModal />
  }

 

  const dispatch = useDispatch();
  let data = [];
   data = useSelector((state)=>state.transportData);
  console.log("saga data is ===", data);
 if(data != null){
  console.warn("data in main component", data);
}
 

  useEffect(() => {
    console.log("use effect called");
   dispatch(transportList())
  }, []);



  return (


    <>
      <div className='container'>
        <div className='row'>
          
        </div>
        <div className='row'>
          <div className='col-lg-3 col-md-3 vh-100' style={{ position: "sticky" }}>
            <Sidebar />
          </div>

          <div className='col-lg-9 col-md-9'>
          <div className='row'>
            <div className='col-md-9 col-lg-9 col-sm-9'>
            <div className='breadCrumb' style={{marginLeft:'2%'}}>
            <BreadCrumb category='Transports' subcategory='Luxury Cars'/>
            </div>
            </div>
            <div className='col-md-3 col-lg-3 col-sm-3'>
            <div className='listFilter float-end'>
            <div className="form-outline mb-0 mx-100">
            <select name="filter" className="form-select mt-2 mx-auto">
            <option>Filter By</option>
            <option>Distance</option>
            <option>Distance</option>
            <option>Likes</option>
            </select>
            </div>
            </div>
            </div>
            
          </div>
            {data.length < 0 ? <Loader /> : ''}
            {data.length > 0 ?
              data.map((item, i) => {
                 console.log("MAPPERDD DATA", item.uploads.featuredImage)
                return (

                  <div key={i} className='row mb-2 mt-2' style={{ backgroundColor: "#fff", minHeight: "30vh", borderRadius: "10px 10px", marginLeft: ".5rem" }}>
                    <div className='col-lg-5 col-md-5'>
                      <Link to="#">
                        {item.uploads.featuredImage != '' ? <img src={require(`${process.env.REACT_APP_FEATURED_IMAGE}/${item.uploads.featuredImage}`)} className="img-fluid mb-2" alt="Featured image" /> :
                          <img src={featureImg1} className="img-fluid mb-2" alt="Featured image" />
                        }
                      </Link>
                    </div>
                    <div className='col-lg-5 col-md-5'>
                      <h3 className='title'>{item.organization.orgName}</h3>
                      <div className="d-grid gap-2  mx-auto d-flex">
                        <button type="button" className="btn primary">
                          <span className="badge bg-dk px-2 py-2">4.6 </span>
                        </button>
                        <span><StarRating value="4.6" /></span>
                        <span className='review-span'><b>540</b> Reviews</span>
                      </div>
                      <ul className='service-desc px-0'>

                        <li data-bs-toggle="tooltip" data-bs-placement="top" title="Owner Name"><span><FaUserAlt /> </span>{`${item.owner?.ownerFirstName} ${item.owner?.ownerLastName}`}</li>
                        {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="Owner Mobile Number"><span><FaPhoneSquareAlt /> </span>{`${item.owner?.ownerMobileNo}`}</li> */}
                        <li data-bs-toggle="tooltip" data-bs-placement="top" title="Vehicle Colour"><span><MdAdminPanelSettings /> </span>{`${item.vehicleColour}`}</li>
                        {item.brand ? <li data-bs-toggle="tooltip" data-bs-placement="top" title="Vehicle Company"><span> <MdAdminPanelSettings /></span>{`${item.brand}`}</li> : ""}
                        {item.model ? <li data-bs-toggle="tooltip" data-bs-placement="top" title="Vehicle Model"><span> <MdAdminPanelSettings /></span>{`${item.model}`}</li> : ""}
                        {item.launchYear ? <li data-bs-toggle="tooltip" data-bs-placement="top" title="Vehicle Model"><span> <MdAdminPanelSettings /></span>{`${item.launchYear}`}</li> : ""}
                      </ul>
                      <div className="d-grid gap-2 col-12 mx-auto mt-1 mb-1">
                        <button className="btn btn-listing" type="button"><Link to={`/single/transport/${item?.id}`} className="btn-link">View Details</Link></button>
                      </div>
                    </div>
                    <div className='col-lg-2 col-md-2' style={{ backgroundColor: "#1e404e" }}>

                      <div>
                        <ul className='likeShare'>
                          <li>
                            <a href="#" className='float-start'>
                            <i className='bx bx-heart sharebox' style={{marginLeft:'-2.5rem'}}></i>
                            </a>
                          </li>
                         
                          <li>
                            <a href="#" className='float-end'>
                            <i className='bx bx-share-alt sharebox' style={{ marginLeft:'4rem'}}></i>
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="d-grid gap-2 col-12 mx-auto mt-5">
                        <button className="btn btn-dk mt-5" type="button" style={{color:'#fff'}} onClick={() => { showModal(true, item.transportId) }}>Get Quotes</button>
                      </div>
                    </div>
                    {/* <div className='row' style={{marginLeft:"0rem", width:"100%"}}>
                  <hr /> */}
                    <div className='col-lg-12 col-md-12 mb-0 mt-0' style={{backgroundColor:'#ebeeef'}}>
                      {/* <hr style={{ margin: "1% 0%" }} /> */}
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4">
                          <a href="#" >
                            <p style={{ display: 'flex', fontSize: '1.2rem', paddingTop:'1%', paddingBottom:'0.5rem',marginBottom:'0rem' }}><GiPathDistance /><span style={{ color: '#333', padding: '0% 5%', fontSize: '.8rem', fontWeight: 'bold', textDecoration: 'none' }}>23.1 KM</span></p>
                          </a>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-8">
                          <a href="#" >
                            <p style={{ display: 'flex', fontSize: '1.2rem', paddingTop:'1%', paddingBottom:'0.5rem',marginBottom:'0rem' }}><MdLocationOn /> <span style={{ color: '#333', padding: '0% 5%', fontSize: '.8rem', fontWeight: 'bold', textDecoration: 'none' }}>{item.organization.area}, {item.organization.state}</span></p>
                          </a>
                        </div>
                      </div>

                    </div>

                  </div>

                )
              }) : <div className='row mb-2 mt-2' style={{ backgroundColor: "#fff", minHeight: "90vh", borderRadius: "10px 10px", marginLeft: ".5rem" }}><p style={{ padding: "20% 29%", fontSize: "3rem" }}>No data available!</p></div>

            }

            {openModal && <QuoteModal closeModal={setModal} serviceId={serviceId} serviceType="transport" />}
            <>
              <Pagination initialPage="1" pageSize="1" totalData={data.length} />
            </>
          </div>
        </div>
      </div>





    </>

  )
}
